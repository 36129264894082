<template>
  <div class="container-fluid">
    <div class="row row--grid">
      <!-- breadcrumb -->
      <div class="col-12">
        <ul class="breadcrumb">
          <li class="breadcrumb__item"><router-link to="/">홈</router-link></li>
          <li class="breadcrumb__item breadcrumb__item--active">소개</li>
        </ul>
      </div>
      <!-- end breadcrumb -->

      <!-- title -->
      <div class="col-12">
        <div class="main__title">
          <h2>AI Teading을 소개합니다.</h2>
          <p>
            인공지능은 미래의 혁신을 이끌어낼 기술 중 하나입니다.
            <br/>
            AI Teading은 <b>인공지능 기술을 손쉽게 체험해 볼 수 있는 사이트</b>입니다.
            <br/>
            이 사이트에서는 오픈 소스로 공개된 인공지능 앱들을 사이트 내에서 이용하거나 링크로 연결된 외부 사이트에서 체험해 볼 수 있습니다.
            <br/>
            다양한 분야의 인공지능 기술의 체험해 볼 수 있으며, 인공 지능의 미래에 대해 궁금해하는 사람들에게도 유익할 것입니다.
            <br/>
            AI Teading을 통해 인공지능의 놀라움과 활용 가능성을 알아보세요!
          </p>
        </div>
      </div>
      <!-- end title -->

      <div class="col-12 col-lg-4">
        <div class="step">
          <span class="step__number">01</span>
          <h3 class="step__title">간단하게 체험 가능합니다</h3>
          <p class="step__text">대부분의 앱들은 특별한 설명이 필요없이 클릭 몇 번으로 체험이 가능합니다. 아무 앱이나 클릭해서 인공지능에 대해 알아보세요.</p>
        </div>
      </div>

      <div class="col-12 col-lg-4">
        <div class="step">
          <span class="step__number">02</span>
          <h3 class="step__title">다양한 종류가 있습니다</h3>
          <p class="step__text">한번씩만 체험해도 많은 시간이 걸릴 정도로 다양한 앱들이 있습니다. 관심 분야를 중심으로 여러 인공지능 앱들을 사용해보세요.</p>
        </div>
      </div>

      <div class="col-12 col-lg-4">
        <div class="step">
          <span class="step__number">03</span>
          <h3 class="step__title">잘 분류되어 있습니다</h3>
          <p class="step__text">검색 영역이나 사이드바를 이용하면 관심 분야의 앱을 쉽게 찾을 수 있습니다. 특정 분야의 앱을 분류 기능을 이용해 찾아보세요.</p>
        </div>
      </div>
    </div>

    <div class="row row--grid">
      <!-- title -->
      <div class="col-12">
        <div class="main__title">
          <h2>AI Teading에 대한 궁금점</h2>
          <p>AI Teading에 대해 궁금할 수 있는 부분들을 정리해 봤습니다. 이외에 궁금한 점이 있다면 아래 메일 주소로 문의해 주세요.</p>
        </div>
      </div>

      <div class="col-12">
        <a href="mailto:codingteading@gmail.com" class="main__load my-2" style='width: 100%; max-width: 250px'>codingteading@gmail.com</a>
      </div>
      <!-- end title -->

      <div class="col-12 col-md-6 col-lg-4">
        <div class="feature">
						<span class="feature__icon">
							<svg height="511pt" viewBox="-106 1 511 511.999" width="511pt" xmlns="http://www.w3.org/2000/svg"><path d="m150.324219 0c-82.613281 0-149.824219 67.210938-149.824219 149.824219v1.867187c0 26.195313 21.3125 47.503906 47.503906 47.503906 7.296875 0 14.308594-1.617187 20.839844-4.808593 16.449219-8.039063 26.667969-24.398438 26.667969-42.695313v-1.867187c0-30.222657 24.589843-54.8125 54.8125-54.8125 30.226562 0 54.816406 24.589843 54.816406 54.8125 0 30.226562-24.589844 54.816406-54.816406 54.816406h-7.222657c-26.195312 0-47.503906 21.308594-47.503906 47.503906v78.492188c0 26.195312 21.308594 47.507812 47.503906 47.507812 26.195313 0 47.503907-21.3125 47.503907-47.507812v-36.480469c64.171875-17.871094 109.542969-76.691406 109.542969-144.332031.003906-82.613281-67.207032-149.824219-149.824219-149.824219zm0 0"/><path d="m142.324219 396.175781c-31.933594 0-57.910157 25.980469-57.910157 57.914063 0 31.929687 25.980469 57.910156 57.910157 57.910156 31.933593 0 57.910156-25.980469 57.910156-57.910156 0-31.933594-25.976563-57.914063-57.910156-57.914063zm0 0"/></svg>
            </span>
          <h3 class="feature__title">추가 요금 결제가 있나요?</h3>
          <p class="feature__text">AI Teading에 포함된 모든 앱들은 무료입니다. 요금 결제의 걱정없이 모든 기능을 활용할 수 있습니다. 단, 외부 사이트의 경우 해당 사이트의 운영 방식에 따라 요금 결제가 발생할 수도 있습니다.</p>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="feature">
						<span class="feature__icon">
							<svg height="511pt" viewBox="-106 1 511 511.999" width="511pt" xmlns="http://www.w3.org/2000/svg"><path d="m150.324219 0c-82.613281 0-149.824219 67.210938-149.824219 149.824219v1.867187c0 26.195313 21.3125 47.503906 47.503906 47.503906 7.296875 0 14.308594-1.617187 20.839844-4.808593 16.449219-8.039063 26.667969-24.398438 26.667969-42.695313v-1.867187c0-30.222657 24.589843-54.8125 54.8125-54.8125 30.226562 0 54.816406 24.589843 54.816406 54.8125 0 30.226562-24.589844 54.816406-54.816406 54.816406h-7.222657c-26.195312 0-47.503906 21.308594-47.503906 47.503906v78.492188c0 26.195312 21.308594 47.507812 47.503906 47.507812 26.195313 0 47.503907-21.3125 47.503907-47.507812v-36.480469c64.171875-17.871094 109.542969-76.691406 109.542969-144.332031.003906-82.613281-67.207032-149.824219-149.824219-149.824219zm0 0"/><path d="m142.324219 396.175781c-31.933594 0-57.910157 25.980469-57.910157 57.914063 0 31.929687 25.980469 57.910156 57.910157 57.910156 31.933593 0 57.910156-25.980469 57.910156-57.910156 0-31.933594-25.976563-57.914063-57.910156-57.914063zm0 0"/></svg>
            </span>
          <h3 class="feature__title">앱들을 외부에 공개해도 되나요?</h3>
          <p class="feature__text">사이트에 포함된 앱들을 외부에 알려주신다면 오히려 감사할 따름입니다. 상업적, 비상업적 목적 모두 마음대로 공개해서 이용하셔도 됩니다. 오픈소스 앱이라면 복사해서 변형해 이용하실 수도 있습니다.</p>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="feature">
						<span class="feature__icon">
							<svg height="511pt" viewBox="-106 1 511 511.999" width="511pt" xmlns="http://www.w3.org/2000/svg"><path d="m150.324219 0c-82.613281 0-149.824219 67.210938-149.824219 149.824219v1.867187c0 26.195313 21.3125 47.503906 47.503906 47.503906 7.296875 0 14.308594-1.617187 20.839844-4.808593 16.449219-8.039063 26.667969-24.398438 26.667969-42.695313v-1.867187c0-30.222657 24.589843-54.8125 54.8125-54.8125 30.226562 0 54.816406 24.589843 54.816406 54.8125 0 30.226562-24.589844 54.816406-54.816406 54.816406h-7.222657c-26.195312 0-47.503906 21.308594-47.503906 47.503906v78.492188c0 26.195312 21.308594 47.507812 47.503906 47.507812 26.195313 0 47.503907-21.3125 47.503907-47.507812v-36.480469c64.171875-17.871094 109.542969-76.691406 109.542969-144.332031.003906-82.613281-67.207032-149.824219-149.824219-149.824219zm0 0"/><path d="m142.324219 396.175781c-31.933594 0-57.910157 25.980469-57.910157 57.914063 0 31.929687 25.980469 57.910156 57.910157 57.910156 31.933593 0 57.910156-25.980469 57.910156-57.910156 0-31.933594-25.976563-57.914063-57.910156-57.914063zm0 0"/></svg>
            </span>
          <h3 class="feature__title">사이트는 어떻게 제작되었나요?</h3>
          <p class="feature__text">오픈소스로 공개되었고 웹에서 동작하는 인공지능 앱들을 오랜 시간에 걸쳐 찾아보았습니다. 선정한 앱들을 가져와 목록화, 범주화 했습니다. 라이선스를 지키고자 출처를 공개하고 가능 범위 내에서 변형했습니다.</p>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="feature">
						<span class="feature__icon">
							<svg height="511pt" viewBox="-106 1 511 511.999" width="511pt" xmlns="http://www.w3.org/2000/svg"><path d="m150.324219 0c-82.613281 0-149.824219 67.210938-149.824219 149.824219v1.867187c0 26.195313 21.3125 47.503906 47.503906 47.503906 7.296875 0 14.308594-1.617187 20.839844-4.808593 16.449219-8.039063 26.667969-24.398438 26.667969-42.695313v-1.867187c0-30.222657 24.589843-54.8125 54.8125-54.8125 30.226562 0 54.816406 24.589843 54.816406 54.8125 0 30.226562-24.589844 54.816406-54.816406 54.816406h-7.222657c-26.195312 0-47.503906 21.308594-47.503906 47.503906v78.492188c0 26.195312 21.308594 47.507812 47.503906 47.507812 26.195313 0 47.503907-21.3125 47.503907-47.507812v-36.480469c64.171875-17.871094 109.542969-76.691406 109.542969-144.332031.003906-82.613281-67.207032-149.824219-149.824219-149.824219zm0 0"/><path d="m142.324219 396.175781c-31.933594 0-57.910157 25.980469-57.910157 57.914063 0 31.929687 25.980469 57.910156 57.910157 57.910156 31.933593 0 57.910156-25.980469 57.910156-57.910156 0-31.933594-25.976563-57.914063-57.910156-57.914063zm0 0"/></svg>
            </span>
          <h3 class="feature__title">작동 않는 앱은 어떻게 하나요?</h3>
          <p class="feature__text">사이트에 포함된 앱은 PC 크롬 브라우저에서 테스트되었습니다. 모바일 보다 성능이 좋은 PC에서 크롬 기반의 브라우저 사용을 부탁드립니다. 계속 작동이 되지 않을 경우에는 메일로 문의 바랍니다.</p>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="feature">
						<span class="feature__icon">
							<svg height="511pt" viewBox="-106 1 511 511.999" width="511pt" xmlns="http://www.w3.org/2000/svg"><path d="m150.324219 0c-82.613281 0-149.824219 67.210938-149.824219 149.824219v1.867187c0 26.195313 21.3125 47.503906 47.503906 47.503906 7.296875 0 14.308594-1.617187 20.839844-4.808593 16.449219-8.039063 26.667969-24.398438 26.667969-42.695313v-1.867187c0-30.222657 24.589843-54.8125 54.8125-54.8125 30.226562 0 54.816406 24.589843 54.816406 54.8125 0 30.226562-24.589844 54.816406-54.816406 54.816406h-7.222657c-26.195312 0-47.503906 21.308594-47.503906 47.503906v78.492188c0 26.195312 21.308594 47.507812 47.503906 47.507812 26.195313 0 47.503907-21.3125 47.503907-47.507812v-36.480469c64.171875-17.871094 109.542969-76.691406 109.542969-144.332031.003906-82.613281-67.207032-149.824219-149.824219-149.824219zm0 0"/><path d="m142.324219 396.175781c-31.933594 0-57.910157 25.980469-57.910157 57.914063 0 31.929687 25.980469 57.910156 57.910157 57.910156 31.933593 0 57.910156-25.980469 57.910156-57.910156 0-31.933594-25.976563-57.914063-57.910156-57.914063zm0 0"/></svg>
            </span>
          <h3 class="feature__title">사이트 제작 목적은 무엇인가요?</h3>
          <p class="feature__text">인공지능이 빠르게 발전하고, 공개된 다양한 앱들이 있음에도 불구하고 쉽게 접근하기 어렵다는 아쉬움이 있었습니다. 누구나 쉽게 인공지능을 체험해 볼 수 있는 환경을 만들자는 취지에서 제작했습니다.</p>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="feature">
						<span class="feature__icon">
							<svg height="511pt" viewBox="-106 1 511 511.999" width="511pt" xmlns="http://www.w3.org/2000/svg"><path d="m150.324219 0c-82.613281 0-149.824219 67.210938-149.824219 149.824219v1.867187c0 26.195313 21.3125 47.503906 47.503906 47.503906 7.296875 0 14.308594-1.617187 20.839844-4.808593 16.449219-8.039063 26.667969-24.398438 26.667969-42.695313v-1.867187c0-30.222657 24.589843-54.8125 54.8125-54.8125 30.226562 0 54.816406 24.589843 54.816406 54.8125 0 30.226562-24.589844 54.816406-54.816406 54.816406h-7.222657c-26.195312 0-47.503906 21.308594-47.503906 47.503906v78.492188c0 26.195312 21.308594 47.507812 47.503906 47.507812 26.195313 0 47.503907-21.3125 47.503907-47.507812v-36.480469c64.171875-17.871094 109.542969-76.691406 109.542969-144.332031.003906-82.613281-67.207032-149.824219-149.824219-149.824219zm0 0"/><path d="m142.324219 396.175781c-31.933594 0-57.910157 25.980469-57.910157 57.914063 0 31.929687 25.980469 57.910156 57.910157 57.910156 31.933593 0 57.910156-25.980469 57.910156-57.910156 0-31.933594-25.976563-57.914063-57.910156-57.914063zm0 0"/></svg>
            </span>
          <h3 class="feature__title">개선 제안은 어떻게 하나요?</h3>
          <p class="feature__text">코딩티딩에서 미처 찾지 못한 인공지능 앱이 있을 수 있습니다. 잘못된 분류나 사용성 개선을 위한 좋은 방법들도 있을 것입니다. AI Teading의 발전에 도움을 주고 싶으시다면 메일로 제안 부탁드립니다. </p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "About",
  data: function() {
    return {
    };
  },
  created() {
    $('html, body').animate({
      scrollTop: 0
    }, 250);
  }
}
</script>
